<template>
  <div class="survey">
    <div class="survey_banner_wraper">
      <div class="survey_banner_container">
        <div class="survey_banner_container_title">AI监测解决方案</div>
        <div class="survey_banner_container_desc">
          机器视觉向更高阶发展，不仅是看得见、看得清，更是看得懂
        </div>
        <div class="survey_banner_container_btn">在线咨询</div>
      </div>
    </div>
    <div class="survey_camera_bg">
      <div class="survey_container">
        <div class="survey_container_title">现有的摄像头总是不够“聪明”？</div>
        <div class="survey_item_wraper">
          <div class="survey_item_left">
            <div class="survey_item_li">
              <div class="survey_item_title">设备改造贵</div>
              <div class="survey_item_desc">
                正在使用的摄像头功能单一，但升级改造 成本高，改造效率低
              </div>
            </div>
          </div>
          <div class="survey_item_right">
            <div class="survey_item_li">
              <div class="survey_item_title">AI场景适应难</div>
              <div class="survey_item_desc">
                AI监测不够智能化，在落地场景中存在细 分差异，算法不够准确
              </div>
            </div>
            <div class="survey_item_li">
              <div class="survey_item_title">无法利用大数据</div>
              <div class="survey_item_desc">
                现有的安防设备无法有效利用大数据，设备
                无法“思考”，不能提前判别异常状况
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="survey_system">
      <div class="survey_system_title">智能办文，同一系统全流程高效办文</div>
      <div class="survey_system_desc">
        各单位使用同一系统，线上统一实现来文呈批、发文办理、电子签批、文件归档等日常办公<br />
        全流程高效简便，网上办文代替人手办文，节约行政成本，提高行政效率，实现最适合区县级的办文系统
      </div>
      <div class="survey_tab">
        <div class="survey_tab_title">
          <div
            class="survey_tab_title_item"
            :class="{ active: activeIndex === 1 }"
            @mouseenter="changeIndex(1)"
          >
            技术架构
          </div>
          <span></span>
          <div
            class="survey_tab_title_item"
            :class="{ active: activeIndex === 2 }"
            @mouseenter="changeIndex(2)"
          >
            场景应用
          </div>
        </div>

        <div class="survey_tab_change">
          <div class="survey_tab_change_item" v-if="activeIndex === 1">
            <div class="survey_tab_change_item_img">
              <img src="@/assets/images/solve/ai/技术架构.png" alt="" />
            </div>
          </div>
          <div class="survey_tab_change_item" v-if="activeIndex === 2">
            <div class="survey_tab_change_item_img">
              <img src="@/assets/images/solve/ai/应用场景.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="survey_swiper_bg">
      <div class="survey_swiper">
        <div class="survey_swiper_title">AI监测，24小时的安全管家</div>
        <div class="survey_swiper_box"></div>
        <el-carousel height="700px" :autoplay="false" ref="swiper">
          <el-carousel-item v-for="(item, index) in swiperList" :key="index">
            <div class="survey_swiper_container">
              <div class="survey_swiper_left">
                <img :src="item.icon" alt="" class="icon" />
                <div class="survey_swiper_left_title">{{ item.title }}</div>
                <div class="survey_swiper_left_desc" v-html="item.desc"></div>
                <div
                  class="survey_swiper_left_item"
                  v-for="(item1, idx1) in item.liList"
                  :key="idx1"
                >
                  <span></span>
                  {{ item1 }}
                </div>
              </div>
              <div class="survey_swiper_right">
                <img :src="item.img" alt="" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="survey_swiper_arrow">
          <div class="survey_swiper_arrow_left" @click="prev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="survey_swiper_arrow_right" @click="next">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="survey_success">
      <div class="survey_success_title">成功案例，与你分享价值所在</div>
      <div class="survey_success_wraper">
        <div @mouseenter="change(1)" @mouseleave="change(0)">
          <transition name="el-zoom-in-center">
            <div class="survey_success_left" v-show="show">
              <div class="survey_success-item_img">
                <img src="@/assets/images/solve/ai/hand.png" alt="" />
                <p>{{ leftData[id1].title }}</p>
              </div>
              <div class="survey_success-item_info">
                {{ leftData[id1].info }}
              </div>
              <div class="survey_success-item_wraper">
                <div class="survey_success-item_li">
                  <img :src="leftData[id1].img1" alt="" />
                  <p>{{ leftData[id1].desc1 }}</p>
                </div>
                <div class="survey_success-item_li">
                  <img :src="leftData[id1].img2" alt="" />
                  <p>{{ leftData[id1].desc2 }}</p>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div @mouseenter="change1(1)" @mouseleave="change1(0)">
          <transition name="el-zoom-in-center">
            <div class="survey_success_right" v-show="show2">
              <div class="survey_success-item_img">
                <img src="@/assets/images/solve/ai/hand.png" alt="" />
                <p>{{ rightData[id2].title }}</p>
              </div>
              <div class="survey_success-item_info">
                {{ rightData[id2].info }}
              </div>
              <div class="survey_success-item_wraper">
                <div class="survey_success-item_li">
                  <img :src="rightData[id2].img1" alt="" />
                  <p>{{ rightData[id2].desc1 }}</p>
                </div>
                <div class="survey_success-item_li">
                  <img :src="rightData[id2].img2" alt="" />
                  <p>{{ rightData[id2].desc2 }}</p>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <solve-footer></solve-footer>
  </div>
</template>

<script>
import $ from 'jquery'
import SolveFooter from '@/components/SolveFooter'
export default {
  name: '',
  components: {
    SolveFooter
  },
  props: {

  },
  data () {
    return {
      show: true,
      show2: true,
      activeIndex: 1,
      swiperList: [
        {
          head: 'AI监测，24小时的安全管家',
          icon: require('@/assets/images/solve/ai/icon1.png'),
          title: '快速部署',
          desc: '无需额外配置设备，快速安装部署，低成本完成现有监控的改造',
          liList: ['AI监测网络部署', 'AI边缘计算服务器部署', 'Web端部署', '微信小程序部署'
          ],
          img: require('@/assets/images/solve/ai/left.png')
        },
        {
          head: 'AI监测，24小时的安全管家',
          icon: require('@/assets/images/solve/ai/icon2.png'),
          title: 'AI场景适应',
          desc: '机器通过自我学习，逐步迭代，以较短的时间优化算法，适应复杂<br/>多样的场景',
          liList: ['态势感知、分析研判、指挥调度、AI应用创新', '安全态势分析、轨迹可视分析、关系图谱分析等', '用户融合画像、人员特征分析、重点人物预警等', '结合各场景的大数据、物联网等为管理人员提供精准研判'
          ],
          img: require('@/assets/images/solve/ai/middle.png')
        },
        {
          head: 'AI监测，24小时的安全管家',
          icon: require('@/assets/images/solve/ai/icon3.png'),
          title: '大数据利用',
          desc: '万亿级的搜索数据、亿级的视频数据、百亿级的用户行为数据，<br/>将场景大数据最大化利用',
          liList: ['任务完成情况', '人员执行情况', '问题统计情况'],
          img: require('@/assets/images/solve/ai/right.png')
        }
      ],
      id1: 0,
      id2: 0,
      leftData: [
        {
          title: '案例一',
          info: ' 新冠病毒传染性强，对密切接或者外来人员均需要隔离14天进行观察。佛山某新冠隔离点通过AI监测密切关注隔离人员的出入情况，一旦发现人员或车辆出入立即告警，由安保人员进行二次确认。同时为避免隔离人员做出过激行为，对天台等危险区域也做了边界跨越防备。',
          desc1: '边界跨越',
          desc2: '人车跨越',
          img1: require('@/assets/images/solve/ai/img1.png'),
          img2: require('@/assets/images/solve/ai/img2.png')
        },
        {
          title: '案例三',
          info: '公司是办公场所，吸烟等不文明行为会严重影响公司形象；另外作为公司的财产等都需要得到有效保护。通过AI监测可以实时监测公司公共区域的吸烟行为，并予以制止；对异常时间段的人员逗留进行监测，及时发现制止潜在的偷盗行为。',
          desc1: '吸烟行为',
          desc2: '人员逗留',
          img1: require('@/assets/images/solve/ai/img5.png'),
          img2: require('@/assets/images/solve/ai/img6.png')
        }
      ],
      rightData: [
        {
          title: '案例二',
          info: '海关仓库是货物清关过程中存放货物的地方。每片区域都属于不同的货主。为防止货物丢失，在货仓内对物体移动及人员、车辆进出做实施分析检测，当发生告警时就安保人员再进行判断是否授权人员。',
          desc1: '物体异动',
          desc2: '人车跨越',
          img1: require('@/assets/images/solve/ai/img3.png'),
          img2: require('@/assets/images/solve/ai/img4.png')
        },
        {
          title: '案例四',
          info: '医院是救死扶伤的地方，医院的生命通道不容堵塞。AI监测通过车辆停留的时间，及时发现生命通道车辆乱停的情况，并发出告警，相关安保人员及时介入，保障通道的畅通。',
          desc1: '车辆乱停',
          desc2: '车辆乱停',
          img1: require('@/assets/images/solve/ai/img7.png'),
          img2: require('@/assets/images/solve/ai/img8.png')
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {
    setTimeout(() => {
      $('.survey_item_left').addClass('fade')
      $('.survey_item_right').addClass('fade')
    }, 100)
  },
  methods: {
    changeIndex (id) {
      this.activeIndex = id
    },
    prev () {
      this.$refs.swiper.prev()
    },
    next () {
      this.$refs.swiper.next()
    },
    change (id) {
      this.show = !this.show
      setTimeout(() => {
        this.id1 = id
        this.show = !this.show
      }, 200)
    },
    change1 (id) {
      this.show2 = !this.show2
      setTimeout(() => {
        this.id2 = id
        this.show2 = !this.show2
      }, 200)
    }
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
.el-carousel {
  /deep/ .el-carousel__indicators--horizontal {
    bottom: 198px;
    left: 212px;
  }
  /deep/ .el-carousel__button {
    width: 14px;
    height: 14px;
    background: #ffffff;
    opacity: 0.5;
    border-radius: 50%;
  }
  /deep/ .el-carousel__indicator.is-active button {
    opacity: 1;
  }
  /deep/ .el-carousel__indicator--horizontal {
    padding: 12px 10px;
  }
  /deep/ .el-carousel__arrow--left,
  /deep/ .el-carousel__arrow--right {
    display: none !important;
  }
}
/deep/ .el-icon-arrow-left,
.el-icon-arrow-right {
  color: #000;
  font-weight: bold;
}
</style>
