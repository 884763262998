<template>
  <div class="solve_solu_footer">
    <div class="solve_solu_footer_img1">
      <img src="@/assets/images/solve/home/img5.png" alt="" />
    </div>
    <div class="solve_solu_footer_img2">
      <img src="@/assets/images/solve/home/img6.png" alt="" />
    </div>
    <div class="solve_solu_footer_img3">
      <img src="@/assets/images/solve/home/img4.png" alt="" />
    </div>
    <div class="solve_solu_footer_content">
      <div class="solve_solu_footer_title">想定制个性方案？</div>
      <div class="solve_solu_footer_btn">
        <div class="solve_solu_footer_bg">立即预约专家</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  props: {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
@import '~@/style/public.scss';

.solve_solu_footer {
  @include container(relative, 300px);

  .solve_solu_footer_img1 {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .solve_solu_footer_img2 {
    position: absolute;
    top: 0;
    right: 0;
  }

  .solve_solu_footer_img3 {
    position: absolute;
    left: 334px;

    img {
      width: 726px;
    }
  }

  .solve_solu_footer_content {
    padding-top: 78px;
    text-align: center;

    .solve_solu_footer_title {
      @include font(#333, 40px, 500);
    }

    .solve_solu_footer_btn {
      @include font(#fff, 24px, 500);
      display: flex;
      justify-content: center;
      .solve_solu_footer_bg {
        width: 300px;
        height: 64px;
        background: linear-gradient(90deg, #52c3ff, #6699ff);
        margin-top: 52px;
        border-radius: 10px;
        text-align: center;
        line-height: 64px;
        margin-left: -20px;
      }
    }
  }
}
</style>
